import React from 'react';

const NotFoundPage = () => (
  <div>
    <h1>Nenalezeno!</h1>
    <p>Bohužel tato stránka neexistuje</p>
  </div>
);

export default NotFoundPage;
